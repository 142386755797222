.login__container{
	background-color: brown;
	height: 100vh;
	width: 100vw;
	display: flex;
	justify-content: center;
	align-items: center;
}

.login__box{
	min-height: 15rem;
	min-width: 20rem;
	background-color: #fff;
	display: flex;
	flex-direction: column;
	padding: 2rem;
	border-radius: 0.2rem;
}

.login__form{
	display: flex;
	flex-direction: column;
	gap: 1rem;
}

.login__title{
	text-align: center;
	margin-bottom: 1rem;
	color: brown;
}

.login__input{
	height: 2rem;
	padding-left: 0.5rem;
	font-size: large;
	color: brown;
}

.login__button{
	height: 2rem;
	cursor: pointer;
	outline: none;
	border: none;
	background-color: rgb(114, 0, 0);
	color: white;
}

.login__button:hover{
	background-color: brown;
}