.news__wrapper{
	margin: 1rem;
}

.news__head{
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 2rem;
	width: 100%;
	margin-bottom: 1rem;
}

.news__title{
	/* color: brown; */
	font-size: large;
}

.news__btn-search{
	border: none;
	background-color: rgb(202, 74, 74);
	color: white;
	padding: 0.5rem 1rem;
	cursor: pointer;
	border-radius: 0.2rem;
}

.news__btn-search:hover{
	background-color: brown;
}

.news__container{
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 0.5rem 0.2rem;
	background-color: #fff;
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3);
	border-radius: 0.4rem;
}

.news__table{
	width: 100%;
	border-spacing: 0.5rem 0.3rem;
}

.news__table-head{
	background-color: #B53259;
	color: #fff;
	height: 2rem;
}

.news__table-header{
	font-family: serif;
}

.news__table-media{
	padding-left: 1rem;
}

.news__btn{
	border: none;
	outline: none;
	color: white;
	padding: 0.3rem 1rem;
	border-radius: 0.2rem;
	cursor: pointer;
}

.news__btn-edit{
	background-color: #B53259;
	margin-right: 0.2rem;
}

.news__btn-edit:hover{
	background-color: #aa1d48;
}

.news__btn-update{
	background-color: #379237;
	margin-right: 0.2rem;
}

.news__btn-update:hover{
	background-color: #167a16;
}

.news__btn-cancel{
	background-color: brown;
}

.news__btn-cancel:hover{
	background-color: rgb(145, 24, 24);
}

.news__btn-group{
	display: flex;
	justify-content: center;
}

.news__edit{
	height: 1.5rem;
	width: 15rem;
	font-size: medium;
	border: none;
	border-bottom: 1px solid black;
	outline: none;
	margin-left: 1rem;
}

.text-center{
	text-align: center;
}

.news_desc_row{
	/* Add the read more dot dots */
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

.news__table-nonews{
	text-align: center;
	padding-top: 0.5rem;
}

.news-disable{
	text-decoration: line-through;
	color: gray;
}