.navbar{
	height: 3rem;
	width: 100%;
	background-color: brown;
	color: white;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 1rem;
}

.navbar__btn-logout{
	border: none;
	outline: none;
	color: #fff;
	background-color: brown;
	padding: 0.5rem;
	border-radius: 0.2rem;
	cursor: pointer;
}

.navbar__btn-logout:hover{
	color: brown;
	background-color: #fff;
	box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.4);
}

.navbar__header{
	display: flex;
	align-items: center;
	gap: 1rem;
}

.navbar__title{
	cursor: pointer;
}

.navbar__navigation{
	display: flex;
	list-style: none;
	gap: 0.2rem;
}

.navbar__li{
	text-decoration: none; 
	padding: 0; 
	margin: 0; 
	color: white; 
	padding: 0.2rem;
	border-radius: 0.2rem;
	cursor: pointer;
}

.navbar__li:hover{
	background-color: white;
	color: brown;
}

.navbar__li.active{
	background-color: white;
	color: brown;
}

.navbar__user{
	display: flex;
	align-items: center;
	gap: 0.5rem;
}