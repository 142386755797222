.scroll__container{
	background-color: #fff;
	width: 45rem;
	padding: 1rem;
	margin: auto;
	margin-top: 1rem;
	border-radius: 0.3rem;
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3);
}

.scroll__title{
	margin-bottom: 0.5rem;
}

.scroll__input-wrapper{
	display: flex;
	gap: 0.3rem;
	margin-bottom: 0.5rem;
}

.scroll__input{
	flex: 1;
	height: 2rem;
	padding: 0 0.2rem;
}

.scroll__btn{
	border: none;
	padding: 0 0.7rem;
	min-width: 4rem;
	color: white;
	cursor: pointer;
	border-radius: 0.2rem;
}

.scroll__btn-add{
	background-color: #379237;
}

.scroll__btn-add:hover{
	background-color: #218021;
}

.scroll__btn-remove{
	background-color: rgb(202, 74, 74);
}

.scroll__btn-remove:hover{
	background-color: rgb(177, 51, 51);
}

.scroll__btn-publish{
	background-color: #005BB7;
	padding: 0.5rem;
}

.scroll__btn-publish:hover{
	background-color: #00458a;
	padding: 0.5rem;
}

.scroll__table-head{
	background-color: #B53259;
	color: white;
	height: 2rem;
	font-family: serif;
}

.scroll__table-cell{
	height: 1.5rem;
}