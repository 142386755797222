.dashboard__wrapper{
	width: 100vw;
	display: flex;
	justify-content: center;
	padding: 0 20rem;
}

.dashboard__container{
	width: 100%;
	height: 20rem;
	background-color: white;
	margin-top: 2rem;
	border-radius: 0.4rem;
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3);

	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	gap: 2rem;
}

.dashboard__title{
	color: brown;
}

.dashboard__btn-group{
	display: flex;
	gap: 1rem;
}

.dashboard__btns{
	border: none;
	outline: none;
	cursor: pointer;
	padding: 0.5rem;
	color: white;
	border-radius: 0.2rem;
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3);
	background-color: brown;
}

.dashboard__btns:hover{
	background-color: rgb(146, 25, 25);
	box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.5);
}