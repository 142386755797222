.newsform__title{
	color: brown;
	margin: 1rem 0;
	text-align: center;
}

.newsform__container{
	background-color: #fff;
	width: 70vw;
	padding: 1rem;
	border-radius: 0.4rem;
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.4);
	margin: auto;
}

.newsform__title-head{
	margin-bottom: 0.5rem;
}

.newsform__title-badge{
	color: white;
	padding: 0 0.5rem;
	border-radius: 1rem;
}

.newsform__title-badge-color{
	background-color: #54B435;
}

.newsform__title-badge-warning{
	background-color: #FFB200;
}

.newsform__title-badge-danger{
	background-color: #CF0A0A;
}

.newsform__title-input{
	width: 100%;
	height: 2rem;
	padding: 0 0.5rem;
	margin-bottom: 1rem;
}

.newsform__desc-head{
	margin-bottom: 0.5rem;
}

.newsform__desc-input{
	width: 100%;
	padding: 0.5rem;
	line-height: 1.5rem;
	margin-bottom: 0.5rem;
}

.newsform__source-container{
	display: flex;
	gap: 0.5rem;
}

.newsform__source-wrapper{
	display: flex;
	align-items: center;
	gap: 0.5rem;
	width: 50%;
}

.newsform__source-select{
	width: 100%;
	font-size: small;
}

.newsform__publish-date{
	display: flex;
	gap: 0.5rem;
}

.newsform__publish-date span{
	line-height: 2.5;
}

.newsform__source-btns{
	width: 30%; 
	display: flex; 
	gap: 0.5rem;
}

.newsform__source-btn{
	flex: 1;
	border: none;
	outline: none;
	color: white;
	border-radius: 0.2rem;
	cursor: pointer;
}

.btn-submit{
	background-color: #379237;
}

.btn-submit:hover{
	background-color: #167a16;
}

.btn-home{
	background-color: rgb(202, 74, 74);
}

.btn-home:hover{
	background-color: brown;
}