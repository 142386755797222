.media__title{
	margin: 0.5rem 0;
	color: brown;
	font-size: larger;
}

.media__container{
	width: 98vw;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 1rem;
	background-color: #fff;
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3);
	border-radius: 0.4rem;
	margin: auto;
}

.media__add{
	width: 100%;
	display: flex;
	justify-content: center;
	gap: 0.5rem;
}

.media__add-input{
	width: 30%;
	height: 2rem;
	padding-left: 0.5rem;
	font-size: large;
}

.media__add-btn{
	border: none;
	/* outline: none; */
	background-color: rgb(202, 74, 74);

	color: white;
	padding: 0 0.5rem;
	cursor: pointer;
	border-radius: 0.2rem;
}

.media__add-btn:hover{
	background-color: brown;
}

.media__subtitle{
	color: brown;
	font-weight: bold;
	font-size: larger;
	margin-top: 0.5rem;
}

.media__table{
	width: 100%;
	border-spacing: 0 0.3rem;
}

.media__table-head{
	background-color: #B53259;
	color: #fff;
	height: 2rem;
}

.media__table-header{
	font-family: serif;
}

.media__table-media{
	padding-left: 1rem;
}

.media-disable{
	text-decoration: line-through;
	color: gray;
}

.media__btn{
	border: none;
	outline: none;
	color: white;
	padding: 0.3rem 1rem;
	border-radius: 0.2rem;
	cursor: pointer;
}

.media__btn-edit{
	background-color: #B53259;
	margin-right: 0.2rem;
}

.media__btn-edit:hover{
	background-color: #aa1d48;
}

.media__btn-update{
	background-color: #379237;
	margin-right: 0.2rem;
}

.media__btn-update:hover{
	background-color: #167a16;
}

.media__btn-cancel{
	background-color: brown;
}

.media__btn-cancel:hover{
	background-color: rgb(145, 24, 24);
}

.media__btn-active{
	background-color: #432C7A;
	min-width: 6rem;
}

.media__btn-active:hover{
	background-color: #37206d;
}

.media__btn-inactive{
	background-color: #0D4C92;
	min-width: 6rem;
}

.media__btn-inactive:hover{
	background-color: #003c81;
}

.media__edit{
	height: 1.5rem;
	width: 15rem;
	font-size: medium;
	border: none;
	border-bottom: 1px solid black;
	outline: none;
	margin-left: 1rem;
}

.text-center{
	text-align: center;
}

.media__pageno{
	margin: 0 0.5rem;
}

.media__btn-pagination{
	background-color: #B53259;
}

.media__btn-pagination:disabled{
	color: black;
	background-color: rgb(223, 223, 223);
}