.usercreate__container{
	margin: auto;
	margin-top: 1rem;
	background-color: #fff;
	width: 40rem;
	padding: 1rem;
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.4);
	border-radius: 0.3rem;
}

.usercreate__input-group{
	display: flex;
	gap: 0.5rem;
	height: 2rem;
}

.usercreate__input-group > *{
	flex: 1;
	padding: 0 0.3rem;
}

.usercreate__checkbox{
	margin: 0.5rem 0;
}

.usercreate__checkbox-label{
	padding-left: 0.5rem;
	cursor: pointer;
}

.userlist__title{
	text-align: center;
	margin: 1rem 0;
	font-weight: bold;
	font-size: large;
	color: #B53259;
}

.userlist__container{
	margin: auto;
	margin-top: 1rem;
	background-color: #fff;
	width: 90vw;
	padding: 1rem;
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.4);
	border-radius: 0.3rem;
}

.usercreate__table-head{
	background-color: #B53259;
	color: white;
	font-family: serif;
	height: 1.5rem;
}

.usercreate__table-cell{
	height: 2rem;
	vertical-align: middle;
}

.usercreate__btn{
	border: none;
	color: white;
	cursor: pointer;
	border-radius: 0.2rem;
}

.usercreate__btn-create{
	background-color: #379237;
	height: 2rem;
	width: 10rem;
	margin-right: 0.5rem;
}

.usercreate__btn-create:hover{
	background-color: #218021;
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.4);
}

.usercreate__btn-update{
	background-color: #005BB7;
	height: 2rem;
	width: 10rem;
	margin-right: 0.5rem;
}

.usercreate__btn-update:hover{
	background-color: #00458a;
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.4);
}

.usercreate__btn-cancel{
	background-color: rgb(202, 74, 74);
	height: 2rem;
	width: 10rem;
}

.usercreate__btn-cancel:hover{
	background-color: rgb(177, 51, 51);
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.4);
}

.usercreate__btn-group{
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 0.5rem;
}

.usercreate__btn-group > * {
	flex: 1;
}

.usercreate__btn-edit{
	background-color: #fff;
	color: #005BB7;
	border: 1px solid #005BB7; 
	padding: 0.2rem 0;
	max-width: 3rem;
}

.usercreate__btn-edit:hover{
	background-color: #005BB7;
	color: white;
}

.usercreate__btn-deactivate{
	background-color: #fff;
	color: #B53259;
	border: 1px solid #B53259;
	padding: 0.2rem 0;
}

.usercreate__btn-deactivate:hover{
	background-color: #B53259;
	color: white;
}

.usercreate__btn-activate{
	background-color: #fff;
	color: #379237;
	border: 1px solid #379237;
	padding: 0.2rem 0;
}

.usercreate__btn-activate:hover{
	background-color: #379237;
	color: white;
}

.usercreate__status-green{
	color: green;
}

.usercreate__status-red{
	color: #B53259;
}