*{
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: 'Signika Negative', sans-serif;
}

:root{
	--theme-margin: 25%;
	/* --theme-color: #2165D4; */
	--theme-color: brown;
	--navbar-height: 5.5rem;
	--scrollbar-height: 2.5rem;
	--navbar-color: #AC1A0B;
	--tag-1-color: #005BB7;
	--tag-2-color: #623FBD;
	--tag-3-color: rgb(5, 141, 182);
	--tag-4-color: rgb(241, 95, 95);
	--tag-5-color: #006F3F;
}

::selection{
	background: var(--theme-color);
	color: white;
}

.body{
	margin: 0 var(--theme-margin);
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: space-between;}

.header{
	width: 100%;
	/* width: calc(100% - calc(var(--theme-margin) * 2)); */
	height: var(--navbar-height);
	background-color: var(--theme-color);
	color: white;
	/* position: fixed;
	
	z-index: 100000; */
}

.sticky-navbar{
	width: calc(100% - calc(var(--theme-margin) * 2));
	position: fixed;
	
	z-index: 100000;
}

.header__container{
	display: flex;
	align-items: center;
	height: var(--navbar-height);
	justify-content: space-between;
	padding: 0 1rem;
}

.header__logo{
	width: 5.3rem;
}

.header__date-section{
  text-align: right;
}

.header__scroll{
	color: black;
	background-color: #fff;
	padding: 0.7rem 0;
	border-bottom: 1px solid var(--theme-color);
	cursor: default;
	font-size: 0.9rem;
	font-family: Georgia, 'Times New Roman', Times, serif;
}
/* 
.header__scroll{
	color: var(--theme-color);
	background-color: #fff;
	padding: 0.5rem 0;
	border-bottom: 1px solid var(--theme-color);
	height: var(--scrollbar-height);
	overflow: hidden;
	position: relative;
	display: flex;
	align-items: center;
} */

.header__scroll-text{
	position: absolute;
	width: max-content;
	margin: 0;
	text-align: left;
	cursor: pointer;

	transform: translateX(100%);
	animation: moving 5s linear infinite;
}

@keyframes moving{
	0%{
	  transform: translateX(100%);
	}
	100%{
	  transform: translateX(-100%);
	}
}
  
.header__scroll-text:hover{
	animation-play-state: paused;
}

.container{
	/* margin: 0.5rem 10rem 1rem 10rem; */
	margin: calc(var(--navbar-height) + var(--scrollbar-height)) 0 1rem 0;
	/* margin: 0.5rem 0; */
	position: relative;
}

.container__btn-group-wrapper{
	display: flex;
	justify-content: space-between;
}

.container__btn-group{
	display: flex;
	justify-content: center;
	gap: 0.3rem;
	margin: 0.5rem 0;
}

.react-datepicker__input-container > input{
	text-align: center !important;
}

.container__media-search{
	min-width: 10rem;
}

.container__btn-return{
	border: none;
	/* background-color: var(--theme-color); */
	background-color: rgb(204, 64, 64);
	color: white;
	padding: 0.3rem 0.5rem;
	border-radius: 0.2rem;
	cursor: pointer;
}

.container__btn-return:hover{
	background-color: var(--theme-color);
	/* background-color: rgb(204, 64, 64); */
}

.container__filter{
	display: flex;
	gap: 0.5rem;
}

.container__btn-filter{
	border: 1px solid rgb(204, 64, 64);
	background-color: none;
	color: rgb(204, 64, 64);
	padding: 0.3rem 0.5rem;
	margin: 0.5rem 0;
	border-radius: 0.2rem;
	cursor: pointer;
}

.container__btn-filter-active{
	border: none;
	background-color: rgb(204, 64, 64);
	color: white;
}

.container__main{
	border-radius: 0.3rem;
	background-color: #fff;
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.4);
}

.news__card{
	min-height: fit-content;
	padding: 0.7rem 1rem 0.5rem 1rem;
	cursor: pointer;
}

.news__card-topbar{
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.news__card-time{
	color: gray;
	margin-bottom: 0.2rem;
	font-size: small;
}

.news__card-title{
	font-size: 1rem;
	font-weight: 600;
	color: var(--theme-color);
	margin-bottom: 0.3rem;
	font-family: Georgia, 'Times New Roman', Times, serif;
}

.news__card-subtitle{
	font-family: Verdana, Geneva, Tahoma, sans-serif;
	font-size: 0.9rem;
	font-weight: normal;
	text-align: justify;
	text-justify: inter-word;
	line-height: 1.2rem;
}

.expand{
	/* Reset the read more dot dots */
	-webkit-line-clamp: unset;
}

.collapse{
	/* Add the read more dot dots */
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

.news__card-more{
	color: #005BB7;
	font-size: 0.9rem;
}

.news__card-date_container{
	margin-top: 0.5rem;
}

.news__card-date{
	font-size: small;
	line-height: 1.5rem;
}

.news__card-source{
	font-size: 0.9rem;
	line-height: 1.5rem;
	color: black;
}

.news__card-footer{
	display: flex;
	justify-content: space-between;
}

.news__card-btn-share{
	border: none;
	color: #005BB7;
	background-color: white;
	border: 1px solid #005BB7;
	/* color: brown;
	background-color: white;
	border: 1px solid brown; */
	padding: 0.2rem 0.5rem;
	border-radius: 0.2rem;
	cursor: pointer;
}

.news__card-btn-share:hover{
	/* background-color: brown; */
	background-color: #005BB7;
	color: white;
}

.news__card-tags{
	align-self: flex-start;
	display: flex;
	gap: 0.2rem;
	flex-wrap: wrap;
	margin-top: 1rem;
}

.news__card-tag{
	color: white;
	padding: 0.1rem 0.2rem;
	border-radius: 0.2rem;
	font-size: small;
}

.news__card-tag:nth-child(1){
	background-color: var(--tag-1-color);
}

.news__card-tag:nth-child(2){
	background-color: var(--tag-2-color);
}

.news__card-tag:nth-child(3){
	background-color: var(--tag-3-color);
}

.news__card-tag:nth-child(4){
	background-color: var(--tag-4-color);
}

.news__card-tag:nth-child(5){
	background-color: var(--tag-5-color);
}

.news__card-blank{
	min-height: 5rem;
	display: flex;
	justify-content: center;
	align-items: center;
	color: var(--theme-color);
	font-weight: 600;
	font-size: large;
	cursor: pointer;
}

.news__card-pagination{
	margin-top: 1rem;
	display: flex;
	justify-content: center;
	gap: 0.5rem;
	color: #AC1A0B;
}

.news__card-btn-pagination{
	border: none;
	outline: none;
	background-color: rgb(204, 64, 64);
	color: white;
	padding: 0.2rem 0.5rem;
	border-radius: 0.2rem;
	cursor: pointer;
}

.news__card-btn-pagination:hover{
	background-color: brown;
}

.news__card-btn-pagination:disabled{
	background-color: rgba(204, 64, 64, 0.486);
}

hr{
	border: 0;
	border-bottom: 1px solid brown;
}

hr:last-child{
	display: none;
}

.container__aside{
	flex: 30%;
	padding: 0 1rem;
}

.datepicker__title{
	margin: 0 0 0.5rem 0;
	text-align: center;
	color: var(--theme-color);
}

#datepicker{
	font-size: small;
}

.aside__search{
	margin-top: 1rem;
}

.aside__search-bar{
	width: 100%;
	height: 1.7rem;
	padding-left: 0.3rem;
}

.aside__tags{
	margin-top: 1rem;
}

.aside__tags > li{
	margin-bottom: 0.5rem;
	cursor: pointer;
}

.footer{
	height: max-content;
	padding: 1rem;
	text-align: center;
	color: white;
	background-color: var(--theme-color);
	clear: both;
	font-size: small;
}

.cursor{
	cursor: pointer;
}

@media screen and (max-width: 600px) {
	:root{
		--theme-margin: 0%;
	}

	.body{
		margin: 0;
	}

	.container{
		margin: calc(var(--navbar-height) + var(--scrollbar-height)) 1rem;
	}
}